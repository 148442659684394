/* eslint-disable menti-react/filename-convention--jsx */
import { Box, Button, Image, Heading, P } from '@mentimeter/ragnar-ui';
import React from 'react';
import { VanillaHeader } from './VanillaHeader';
import folder from './open-folder.svg';

export const NotFoundPage = () => {
  return (
    <>
      <Heading fontSize={7} textAlign="center">
        This page does not exist
      </Heading>
      <P textAlign="center">
        Are you sure you typed it in correctly? If you followed a link to this
        address, please let us know!
      </P>
      <Box width="100%" alignItems="center">
        <Box flexDirection="row" p={3}>
          <Button size="large" variant="primary" href="/">
            Go back home
          </Button>
          <Button
            size="large"
            variant="tertiary"
            href="mailto:hello@mentimeter.com"
            ml={3}
          >
            Contact support
          </Button>
        </Box>
      </Box>
    </>
  );
};

export const NotAllowedPage = () => {
  return (
    <>
      <Heading fontSize={7} textAlign="center">
        Sorry, this presentation is not available!
      </Heading>
      <P textAlign="center">
        Explore ways of using Mentimeter by looking at our best practice
        examples.
      </P>
      <Box width="100%" alignItems="center">
        <Box flexDirection="row" p={3}>
          <Button size="large" variant="primary" href="/templates">
            Go to the templates
          </Button>
        </Box>
      </Box>
    </>
  );
};

export const SomethingWentWrongPage = () => {
  return (
    <>
      <Heading fontSize={7} textAlign="center">
        Something went wrong
      </Heading>
      <P textAlign="center">
        Something appears to have gone wrong on our end. Wait for a while and
        try again. If the problem persists you can also contact us.
      </P>
      <Box width="100%" alignItems="center">
        <Box flexDirection="row" p={3}>
          <Button
            size="large"
            variant="primary"
            href="mailto:hello@mentimeter.com"
          >
            E-mail us
          </Button>
        </Box>
      </Box>
    </>
  );
};

export const ErrorWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box height="100vh" bg="bg">
      <VanillaHeader />
      <Box width="100%" alignItems="center">
        <Box maxWidth="500px" alignItems="center" mx={[2, null]} mt={5}>
          <Image
            src={folder.src}
            alt="illustration"
            height="280px"
            width="280px"
          />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const ErrorPage = ({ errorCode }: { errorCode: number | undefined }) => {
  const content = React.useMemo(() => {
    switch (errorCode) {
      case 404:
        return <NotFoundPage />;
      case 401:
        return <NotAllowedPage />;
      default:
        return <SomethingWentWrongPage />;
    }
  }, [errorCode]);
  return <ErrorWrapper>{content}</ErrorWrapper>;
};

export { VanillaHeader };
