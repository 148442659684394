'use client';

import { ErrorWrapper, NotFoundPage } from '@mentimeter/error-pages';

const NotFound = () => {
  return (
    <ErrorWrapper>
      <NotFoundPage />
    </ErrorWrapper>
  );
};

export default NotFound;
